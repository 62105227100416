import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "create-a-local-development-environment"
    }}>{`Create a local Development Environment`}</h2>
    <p>{`The easiest way to work with Ocelot locally, is by either using `}<a parentName="p" {...{
        "href": "https://docs.docker.com/compose/"
      }}>{`docker compose`}</a>{`
or a local installation of kubernetes, like `}<a parentName="p" {...{
        "href": "https://kind.sigs.k8s.io/"
      }}>{`KinD`}</a>{`, `}<a parentName="p" {...{
        "href": "https://k3s.io/"
      }}>{`K3s`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://minikube.sigs.k8s.io/docs/start/"
      }}>{`Minikube`}</a>{`, etc.`}</p>
    <p>{`In this tutorial we will proceed by using docker compose.`}</p>
    <p>{`In the following configuration we setup all the required components for both, Ocelot and Tyk API Gateway.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`name: ocelot-oidc-provider-sample
services:
  # Ocelot components
  db:
    image: postgres
    environment:
      - POSTGRES_DB=ocelot
      - POSTGRES_USER=ocelot
      - POSTGRES_PASSWORD=mypassword
  ocelot:
    image: yanchware/ocelot-middleware:v2.3.2
    environment:
      - SPRING_PROFILES_ACTIVE=postgres,local
      - OCELOT_DB_CREDENTIALS=\${DB_CREDS}
    depends_on:
      db:
        condition: service_started
    healthcheck:
      test: wget --no-verbose --tries=1 --spider http://localhost:8080/health || exit 1
      interval: 10s
      retries: 5
      start_period: 10s
      timeout: 2s
  init-ocelot:
    image: alpine
    command: /init-ocelot.sh
    volumes:
      - ./init-ocelot.sh:/init-ocelot.sh
    depends_on:
      ocelot:
        condition: service_healthy

  # Tyk Gateway components
  gateway:
    image: tykio/tyk-gateway:v4.2
    ports:
      - 8080:8080
    volumes:
      - ./gateway-config/standalone.conf:/opt/tyk-gateway/tyk.conf
      - ./gateway-config/apps:/opt/tyk-gateway/apps
      - ./gateway-config/middleware:/opt/tyk-gateway/middleware
    depends_on:
      gateway-redis:
        condition: service_started
      init-ocelot:
        condition: service_completed_successfully
  gateway-redis:
    image: redis:6.2.7-alpine
    ports:
      - 6379:6379
`}</code></pre>
    <p>{`You will then be able to call:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl http://localhost:8080/httpbin/anything
`}</code></pre>
    <p>{`Ocelot will allow the request as the operator defined the Business Resource to allow anonymous traffic.
However, the downstream service receives a JWT that mark the legitimacy of the request and the intent of the user agent.`}</p>
    <p>{`A sample response for HttpBin containing information about the received request from the Tyk Gateway:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "args": {},
  "data": "",
  "files": {},
  "form": {},
  "headers": {
    "Accept": "*/*",
    "Accept-Encoding": "gzip",
    "Host": "httpbin.org",
    "User-Agent": "curl/7.79.1",
    "X-Amzn-Trace-Id": "Root=1-63485ce9-4015850b3271fe443128ddae",
    "X-Jwt": "eyJ4NXQiOiJpeVMyUU4zbjFYcjczX1dBa2tqSHMwXzY3MmciLCJhbGciOiJSUzUxMiJ9.eyJpc3MiOiJvY2Vsb3QiLCJhdWQiOlsiaHR0cGJpbiJdLCJleHAiOjE2NjU2ODY4MjEsImp0aSI6IlVYT3FzdW05aDFBVVhZdFEyMEhSOUEiLCJpYXQiOjE2NjU2ODY3NjEsIm5iZiI6MTY2NTY4NjY0MSwic3ViIjoiYW5vbnltb3VzLWYyZmY3MTkwLTQ5MzEtNGFjNi04ZjdmLTA1ZWQ1N2RjNjY0YyIsIm5vbmNlIjoiMDQ5NjlkYTMtZDE4OC00MTViLTkxYzMtZmExMWRkN2I1MWYzIiwicmVzb3VyY2UiOiIyNWRjZjQ4ZC00YmEwLTQzNDUtYTQ0Ny04YWNkZDFkOGNiYmYiLCJwYXRoIjoiL2h0dHBiaW4vYW55dGhpbmciLCJhbXIiOltdLCJhY3IiOltdLCJzY29wZSI6W10sImNsaWVudCI6InVua25vd24tY2xpZW50In0.ItQkSbXMBA-exjgGlSVYyYESe5fNYn5I0niyWiT5i4NDGOU2cc_1hAARkMyyj86rWliq4LfujbWicprcXAkLWPrxGbQd5T7f3JC7o8bkY3tvcvk5PKar95zHA6TxSIMbrKLtkxKe8waZj9rEtQoI4BM6mAx-46xy3jF1mB9lYCExSbHPTPf7LxWjAshkVRm9JQEiLKD9u4y5SV5nzVr6bR75FsgNlcd0xPd-31Snme8NjTSqomg_E2Wy-wbHW98fnL52nBnvYrwYjrIe4mlvLc_dO-9JT5nkdv8SeNID6jU_d9smjfNtihVMafPsdTxErJqJcI9hnjbYxVtCz3RGIw"
  },
  "json": null,
  "method": "GET",
  "origin": "xxx.xxx.xxx.xxx, xxx.xxx.xxx.xxx",
  "url": "http://httpbin.org/anything"
}
`}</code></pre>
    <p>{`The token contained in the X-JWT header has the following payload:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "iss": "ocelot",
  "aud": [
    "httpbin"
  ],
  "exp": 1665686821,
  "jti": "UXOqsum9h1AUXYtQ20HR9A",
  "iat": 1665686761,
  "nbf": 1665686641,
  "sub": "anonymous-f2ff7190-4931-4ac6-8f7f-05ed57dc664c",
  "nonce": "04969da3-d188-415b-91c3-fa11dd7b51f3",
  "resource": "25dcf48d-4ba0-4345-a447-8acdd1d8cbbf",
  "path": "/httpbin/anything",
  "amr": [],
  "acr": [],
  "scope": [],
  "client": "unknown-client"
}
`}</code></pre>
    <p>{`For brevity, we have not reported here the configuration of the Tyk Gateway, but you can find the whole sample
on our `}<a parentName="p" {...{
        "href": "https://github.com/YanchWare/ocelot-samples"
      }}>{`github repo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      